import React, { useEffect, useState, MouseEvent } from "react";
import { Privacy } from "../components/Footer/Privacy";
import { usePrivacyPolicy } from "./usePrivacyPolicy";
import MuiDialogContent from "@material-ui/core/DialogContent";
import styles from "../components/Footer/styles.mod.scss";
import { TermsOfService } from "../components/Footer/TermsOfService";
import { Revocation } from "../components/Footer/Revocation";
import { LegalNames } from "../types";

export const useModal = () => {
  const [open, setOpen] = useState(false);
  const [agreementContent, setAgreementContent] = useState<any>(Privacy);
  const [isPrivacyPolicyContent, setIsPrivacyPolicyContent] = useState(false);
  const privacyPolicy = usePrivacyPolicy();

  useEffect(() => {
    if (privacyPolicy?.data?.content) {
      setIsPrivacyPolicyContent(true);
    }
  }, [privacyPolicy]);

  const privacyPolicyContent = (
    <MuiDialogContent
      className={styles.footer__privacyPolicy}
      dangerouslySetInnerHTML={{
        __html:
          privacyPolicy?.data?.content || privacyPolicy.error?.message || "",
      }}
    />
  );

  const onChangeModalContent = () => {
    setAgreementContent(privacyPolicyContent);
  };

  const agreements = {
    agb: (
      <TermsOfService
        isSeparatePage={false}
        onChangeModalContent={onChangeModalContent}
      />
    ),
    impressum: Revocation,
    widerruf: Privacy,
    datenschutz: privacyPolicyContent,
  };

  const handleClickOpen = (
    agreementName: "agb" | "impressum" | "widerruf" | "datenschutz"
  ) => (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.nativeEvent.preventDefault();
    history.pushState(null, "", `#${agreementName}`);
    setAgreementContent(agreements[agreementName]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const { hash } = window.location;
    const param = hash.replace("#", "");

    if (isPrivacyPolicyContent) {
      switch (param) {
        case LegalNames.Agb:
          handleClickOpen(LegalNames.Agb)();
          break;
        case LegalNames.Datenschutz:
          handleClickOpen(LegalNames.Datenschutz)();
          break;
        case LegalNames.Impressum:
          handleClickOpen(LegalNames.Impressum)();
          break;
        case LegalNames.Widerruf:
          handleClickOpen(LegalNames.Widerruf)();
          break;

        default:
          break;
      }
    }
  }, [isPrivacyPolicyContent]);

  return {
    open: open,
    agreementContent: agreementContent,
    isPrivacyPolicyContent: isPrivacyPolicyContent,
    handleClickOpen,
    handleClose: handleClose,
  };
};
