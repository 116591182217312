import React, { ReactElement } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

import styles from "./styles.mod.scss";

export const Revocation = (): ReactElement => {
  return (
    <React.Fragment>
      <MuiDialogTitle disableTypography>
        <h2 className={styles.footer__modalTitle}>Impressum</h2>
      </MuiDialogTitle>
      <MuiDialogContent dividers className={styles.footer__modalContent}>
        <p>Stromee ist eine eingetragene Marke der homee GmbH</p>
        <p>
          <b>Geschäftssitz</b>
          <br />
          <span>Viktoria-Luise-Platz 7</span>
          <br />
          <span>10777 Berlin</span>
          <br />
        </p>
        <p>
          <span>Telefon: 030 - 20006042</span>
          <br />
          <span>E-Mail: hello@stromee.de</span>
          <br />
        </p>
        <p>
          <b>Handelsregister</b>
          <br />
          <span>Amtsgericht Charlottenburg</span>
          <br />
          <span>Handelsregister Nr. HRB 209193</span>
          <br />
        </p>
        <p>
          <b>Umsatzsteuer-Identifikationsnummer</b>
          <br />
          <span>DE 321831805</span>
          <br />
        </p>
        <p>
          <b>Geschäftsführer</b>
          <br />
          <span>Mario Weißensteiner, Waldemar Wunder</span>
          <br />
        </p>
        <p>
          <b>Keine Gewähr für Inhalte</b>
          <br />
          Alle in unseren Internet-Seiten enthaltenen Angaben und Informationen
          wurden von uns oder Dritten sorgfältig recherchiert und geprüft. Für
          Richtigkeit, Vollständigkeit und Aktualität können jedoch weder wir
          noch Dritte die Gewähr übernehmen. Dies gilt auch für Internetseiten
          anderer Anbieter, zu denen du über einen Hyperlink gelangst und für
          deren Inhalt wir keine Verantwortung übernehmen.
        </p>
        <p>
          <b>Copyright</b>
          <br />
          Die durch uns erstellten Inhalte und Werke auf diesen Seiten
          unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht von der homee GmbH erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Solltest du trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
      </MuiDialogContent>
    </React.Fragment>
  );
};
