import React, { ReactElement } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

import styles from "./styles.mod.scss";

export const Privacy = (): ReactElement => {
  return (
    <React.Fragment>
      <MuiDialogTitle disableTypography>
        <h2 className={styles.footer__modalTitle}>
          Widerrufsbelehrung & Widerrufsrecht
        </h2>
      </MuiDialogTitle>
      <MuiDialogContent dividers className={styles.footer__modalContent}>
        <p>
          Du hast das Recht, diesen Vertrag binnen vierzehn Tagen ohne Angabe
          von Gründen zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab
          dem Tag des Vertragsabschlusses. Um dein Widerrufsrecht auszuüben,
          musst du uns, der homee GmbH, per E-Mail an{" "}
          <a href="mailto:hello@stromee.de">hello@stromee.de</a> eine kurze
          Nachricht übersenden, aus der klar hervorgeht, dass du deinen Vertrag
          widerrufen möchtest. Du kannst dafür das untenstehende
          Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben
          ist. Zur Wahrung der Widerrufsfrist reicht es aus, wenn du die
          Nachricht über deinen Widerruf vor Ablauf der Frist absendest. Eine
          schriftliche Einsendung per Post ist nicht notwendig, eine kurze
          E-Mail ist ausreichend.
        </p>
        <h2>Folgen des Widerrufs</h2>
        <p>
          Wenn du den Vertrag widerrufst, haben wir dir alle Zahlungen, die wir
          von dir erhalten haben, unverzüglich und spätestens binnen 14 Tagen ab
          dem Tag zurückzuzahlen, an dem die Mitteilung über deinen Widerruf
          dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
          verwenden wir dasselbe Zahlungsmittel, das du bei der ursprünglichen
          Transaktion eingesetzt hast, es sei denn, mit dir wurde ausdrücklich
          etwas anderes vereinbart; in keinem Fall werden dir wegen dieser
          Rückzahlung Entgelte berechnet.
        </p>
        <p>
          Wenn du einen Vertrag abgeschlossen hast, bei dem dir auch physische
          Produkte geliefert werden, hast du diese unverzüglich an die homee
          GmbH zurückzusenden.
        </p>
        <p>
          Wenn du verlangt hast dass Dienstleistungen oder die Lieferung von
          Strom während der Widerrufsfrist beginnen soll, so hast du uns einen
          angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem
          Zeitpunkt, zu dem du uns von der Ausübung des Widerrufsrechts
          hinsichtlich dieses Vertrags unterrichtest, bereits erbrachten
          Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag
          vorgesehenen Dienstleistungen entspricht.
        </p>
        <h2>Muster-Widerrufsformular</h2>
        <p>
          Wenn du den Vertrag widerrufen willst, kannst du folgende Formulierung
          verwenden.
        </p>
        <p>
          Eine schriftliche Einsendung des Formulars per Post ist nicht
          notwendig. Bitte sende das Formular an die homee GmbH über
          hello@stromee.de.
        </p>
        <p>
          Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
          Vertrag über den Kauf der folgenden Waren (*) die Erbringung der
          folgenden Dienstleistung (*)
        </p>
        <p>Bestellt am (*) / erhalten am (*)</p>
        <p>Name des /der Verbraucher(s)</p>
        <p>Anschrift des / der Verbraucher(s)</p>
        <p>Datum</p>
        <p>(*) Unzutreffendes streichen</p>
      </MuiDialogContent>
    </React.Fragment>
  );
};
