import { useState, useEffect } from "react";

import { createRequestState } from "../utils";
import { getPrivacyPolicy } from "../services";
import { ResponseError } from "../services/request";

interface PrivacyPolicy {
  success: boolean;
  content: string;
}

export const usePrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(
    createRequestState<PrivacyPolicy>()
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setPrivacyPolicy({ isPending: true, data: null, error: null });
        const data = await getPrivacyPolicy();
        setPrivacyPolicy({ isPending: false, data, error: null });
      } catch (error) {
        setPrivacyPolicy({
          isPending: false,
          data: null,
          error: new ResponseError(error),
        });
      }
    };
    fetchData();
  }, []);

  return privacyPolicy;
};
